import {
    Avatar,
    BlockStack, Button,
    Card,
    Divider, IndexTable,
    InlineStack,
    MediaCard,
    Modal, ProgressBar, ResourceItem,
    ResourceList,
    Text,
    Thumbnail
} from "@shopify/polaris";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../lib/contextLib";
import {useLocation} from "react-router-dom";
import CustomDrawer from "./common/custom_drawer";
import {getShopifyProductImagesAPI} from "../servies/products.services";
import {createPredictionApi, getPredictionsByProductApi, getPredictionStatus} from "../servies/prediction.services";
import ViewDrawerIndexTableRow from "./ViewDrawerIndexTableRow";


export const ViewDrawer = ({
                               productID,
                               productName,
                               productImage,
                               productImages,
                               predictions,
                               isPredictionCreated,

                               isImageModalOpen,
                               setIsImageModalOpen,
                               selectedProductID,
                               setSelectedProductID,

                               view
                           }) => {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const [loading, setLoading] = useState(false);
    const [gettingPredictionStatus, setGettingPredictionStatus] = useState([]);
    const [productImagesLocal, setProductImagesLocal] = useState(productImages);


    const {
        isRefresh, setIsRefresh,
    } = useContext(AppContext);


    const getProductPrediction = () => {

        const inputData = {
            product_id: selectedProductID
        }
        setGettingPredictionStatus(true)
        getPredictionsByProductApi(params.toString(), inputData)
            .then(res => {
                const data = res.data;

                if (res.data === null) {
                    console.log('Error', res.error);
                    return;
                }

                console.log('data', data)
                const aiImages = data.ai_images;

                console.log('aiImages', aiImages)
                // setAIImagesData(aiImages)
                // setIsAIImagesData(aiImages.length > 0)


                // setPredictionPercentage(data.percentage_completed)
                // console.log('predictionPercentage', predictionPercentage)


            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setGettingPredictionStatus(false)
            })
    }

    const getProductImage = ()=>{
        const inputData = {
            product_id: selectedProductID
        }
        setLoading(true)
        getShopifyProductImagesAPI(params.toString(), inputData)
            .then(res => {
                const data = res.data;
                console.log(data)
                if (res.error !== '') {
                    console.log('Error', res.error);
                    return;
                }
                console.log('data', data)
                const images = data.images;
                setProductImagesLocal(images);
                console.log('aiImages', images)

            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {

    }, []);

    const resourceName = {singular: 'AI Image', plural: 'AI Images'};
    const rowMarkup = predictions.map((
            {
                id,
                prediction_id,
                prediction_status,
                state,
                video,
                prediction_result,
                user_prompt,
                start_image_url,
                end_image_url,
                created_at
            }, index) => (
            <ViewDrawerIndexTableRow
                key={id}
                id={id}
                productName={productName}
                predictionId={prediction_id}
                predictionStatus={prediction_status}
                state={state}
                video={video}
                predictionResult={prediction_result}
                userPrompt={user_prompt}
                startImageUrl={start_image_url}
                endImageUrl={end_image_url}
                CreatedAt={created_at}

                index={index}
            />
        ),
    );

    return <CustomDrawer
        placement={'bottom'}
        size={'calc(100% - 120px)'}
        open={isImageModalOpen}
        setOpen={setIsImageModalOpen}
        title={productName}
    >

        <IndexTable
            resourceName={resourceName}
            itemCount={productImagesLocal.length}
            selectable={false}
            headings={[
                {title: 'Start Image'},
                {title: 'End Image'},
                {title: 'Prompt'},
                {title: 'AI Video'},
                // {title: 'Created At'},
            ]}

            loading={loading}
        >
            {rowMarkup}
        </IndexTable>


    </CustomDrawer>
}