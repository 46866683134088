import Panel from "rsuite/Panel";
import {Button, Form, Table, Toggle, Input, SelectPicker, TagPicker, Text as RText, TagInput} from "rsuite";
import {useNavigate} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {getAuthSession} from "../../../servies/common.services";
import ToasterMessage from "../../../components/common/toaster_message";

import {getSettingsApi, updateLumaCookiesApi, updateSettings} from "../../../servies/settings.services";
import {CustomModal} from "../../../components/common/common_modals";

const {Column, HeaderCell, Cell} = Table;

export const ViewSettings = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [paramsData, setParamsData] = useState('');
    const [authData, setAuthData] = useState({});
    const [placement, setPlacement] = React.useState();
    const [selectedRow, setSelectedRow] = useState({});
    const [settingsData, setSettingsData] = useState({});
    const [openSettingFormModal, setOpenSettingFormModal] = useState(false);
    const [isSettingUpdating, setIsSettingUpdating] = useState(false);
    const [isCookiesUpdating, setIsCookiesUpdating] = useState(false);

    const navigate = useNavigate();

    const [formValue, setFormValue] = React.useState({name: '', value: ''});

    const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref}/>);


    const handelUpdateSettings = () => {
        setIsSettingUpdating(true);
        const inputData = {
            name: formValue.name,
            value: formValue.value,
        };

        updateSettings(paramsData, inputData)
            .then((res) => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }
                console.log('updateSettings', data)
                ToasterMessage.success(res.message);

            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                setIsSettingUpdating(false);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                console.log('finally');
                setIsSettingUpdating(false);
                setOpenSettingFormModal(false);
            })
    }

    const renderDynamicComponent = ({type, value, selected, onChange, readOnly, isTable}) => {
        const commonProps = {
            readOnly,
        };
        console.log({value,type},formValue?.value?.value)

        if (type === 'list') {
            return (
                <TagInput
                    {...commonProps}
                    defaultValue={Array.isArray(value) ? value : []}
                    onChange={onChange}
                />
            );
        }
        if (type === 'dropdown') {
            return (
                <SelectPicker
                    data={value.map((item) => ({label: item, value: item}))}
                    value={selected}

                    {...commonProps}
                    onChange={(newValue) => {
                        if (onChange) {
                            onChange(newValue); // Update the selected value
                        }
                    }}
                />
            );
        }
        if (type === 'dropdown_multi') {
            return (
                <TagPicker
                    data={value.map((item) => ({label: item, value: item}))}
                    value={selected}

                    {...commonProps}
                    onChange={(newValue) => {
                        if (onChange) {
                            onChange(newValue); // Update the selected value
                        }
                    }}
                />
            );
        }
        if (type === 'text') {

            if (isTable) {
                // Render a simple styled div for table layout
                return (
                    <RText>
                        {value}
                    </RText>
                );
            }
            // Render Form.Control with Textarea for modal layout
            return (
                <Form.Control
                    value={typeof value === 'string' ? value : ''}
                    accepter={Textarea}
                    rows={5}
                    {...commonProps}
                    onChange={(newValue) => {
                        if (onChange) {
                            onChange(newValue); // Update the selected value
                        }
                    }}
                />
            );
        }
        return null;
    };

    const updateSettingsDataModalRender = () => (
        <CustomModal
            open={openSettingFormModal}
            setOpen={setOpenSettingFormModal}
            title="Update Shop Data"
            leftButtonText="Update"
            rightButtonText="Cancel"
            leftIsLoading={isSettingUpdating}
            rightOnClick={() => setOpenSettingFormModal(false)}
            leftOnClick={handelUpdateSettings}
        >
            <Form fluid onChange={setFormValue} formValue={formValue}>
                <Form.Group controlId="name" aria-disabled>
                    <Form.ControlLabel>Name</Form.ControlLabel>
                    <Form.Control name="name" disabled/>
                </Form.Group>
                <Form.Group controlId="value">
                    <Form.ControlLabel>Value</Form.ControlLabel>
                    {renderDynamicComponent({
                        ...formValue?.value,
                        selected: formValue?.value?.selected, // Pass the current selected value for dropdown
                        onChange: (newValue) => {
                            setFormValue((prev) => {
                                if (formValue?.value?.type === 'dropdown') {
                                    return {
                                        ...prev,
                                        value: {...prev.value, selected: newValue},
                                    };
                                }
                                if (formValue?.value?.type === 'dropdown_multi') {
                                    return {
                                        ...prev,
                                        value: {...prev.value, selected: newValue},
                                    };
                                }

                                if (formValue?.value?.type === 'list') {
                                    return {
                                        ...prev,
                                        value: { ...prev.value, value: newValue },
                                    };
                                }
                                if (formValue?.value?.type === 'text') {
                                    return {
                                        ...prev,
                                        value: { ...prev.value, value: newValue },
                                    };
                                }
                                return prev;
                            });
                        },
                    })}
                </Form.Group>
            </Form>
        </CustomModal>
    );

    const renderComponent = (rowData) =>
        renderDynamicComponent({
            ...rowData.value,
            selected: rowData.value.selected,
            readOnly: true,
            isTable: true, // Indicates this is for a table layout
        })


    const updateLumaCookies = () => {
        setIsCookiesUpdating(true);
        updateLumaCookiesApi(paramsData)
            .then((res) => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }
                console.log(res)
                ToasterMessage.success(res.message);
            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                console.log('finally');
                setIsCookiesUpdating(false);
            })

    }

    const getSettingsData = (params) => {
        setIsLoading(true);

        getSettingsApi(params.toString())
            .then(res => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }

                setSettingsData(data);

            })
            .catch(reason => {
                ToasterMessage.error(reason.toString() + 'Settings');
                console.log(reason);
            })
            .finally(() => setIsLoading(false));
    }


    useEffect(() => {
        const authSession = getAuthSession();
        if (authSession === null) {
            navigate('/admin/login');
            return;
        }

        if (openSettingFormModal) {
            return;
        }

        const token = authSession.token;
        setAuthData(authSession);

        const params = `token=${token}&is_admin=true`;
        setParamsData(params);

        getSettingsData(params);


    }, [openSettingFormModal])


    return (
        <Fragment>
            {updateSettingsDataModalRender()}
            <Panel>
                <Button
                    appearance='primary'
                    Style='margin-bottom: 10px'
                    onClick={updateLumaCookies}
                    loading={isCookiesUpdating}
                >
                    Update Luma Cookies
                </Button>

                <Table
                    height={500}
                    // width={800}
                    bordered
                    hover
                    data={settingsData}
                    loading={isLoading}
                    onRowClick={data => {
                        setSelectedRow(data);
                    }}
                >
                    <Column>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id"/>
                    </Column>

                    <Column resizable={true} fullText={true}>
                        <HeaderCell>Name</HeaderCell>
                        <Cell dataKey='name'/>
                    </Column>

                    <Column resizable={true} width={500} fullText={true} flexGrow={1} >
                        <HeaderCell>Value</HeaderCell>
                        <Cell style={{ padding: '5px' }}>
                            {(rowData) => renderComponent(rowData)}
                        </Cell>
                    </Column>


                    <Column fixed="right" resizable={true}>
                        <HeaderCell>Actions</HeaderCell>

                        <Cell style={{padding: '6px'}}>
                            {rowData => (
                                <div>
                                    <Button
                                        appearance="link"
                                        onClick={() => {
                                            setSelectedRow(rowData);
                                            setFormValue({name: rowData.name, value: rowData.value});
                                            setOpenSettingFormModal(true);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            )}
                        </Cell>
                    </Column>

                </Table>

            </Panel>
        </Fragment>
    )
}