import React, {useContext, useEffect, useState} from "react";
import {
    BlockStack,
    Button,
    Card,
    Icon,
    IndexTable,
    InlineStack,
    MediaCard,
    ProgressBar,
    Text,
    Thumbnail, VideoThumbnail
} from "@shopify/polaris";
import {Icon as RIcon} from '@rsuite/icons';
import {AppContext} from "../lib/contextLib";
import {
    getPredictionFeedbackApi,
    getPredictionStatusApi, sendFeedbackApi
} from "../servies/prediction.services";
import {facebookPublishImage} from "../servies/facebook.services";
import {formatDate, handleMouseMoveZoom, handleMouseOutZoom, onThumbMouseEnter, onThumbMouseLeave} from "./common/util";
import {CustomModal} from "./common/common_modals";
import {useLocation} from "react-router-dom";
import {Badge, IconButton, Placeholder, Tabs} from "rsuite";
import {ReactComponent as thumbsUpSvg} from "../assets/thumbs_up.svg";
import {ReactComponent as thumbsDownSvg} from "../assets/thumbs_down.svg";
import {ReactComponent as thumbsUpActiveSvg} from "../assets/thumb_up_active.svg";
import {ReactComponent as thumbsDownActiveSvg} from "../assets/thumb_down_active.svg";
import ToasterMessage from "./common/toaster_message";
import ThumbFeedback from "./common/thumbFeedback";
import {ThumbsDownIcon, ThumbsUpIcon} from "@shopify/polaris-icons";


export default function ViewDrawerIndexTableRow({
                                                    id,
                                                    productName,
                                                    predictionId,
                                                    predictionStatus,
                                                    state,
                                                    video,
                                                    predictionResult,
                                                    userPrompt,
                                                    startImageUrl,
                                                    endImageUrl,
                                                    CreatedAt,
                                                    index
                                                }) {
    const {search} = useLocation();
    const params = new URLSearchParams(search);

    const [showError, setShowError] = useState(false);
    const [isShareOnInstagram, setIsShareOnInstagram] = useState(false);
    const [isCreatingAIVideo, setIsCreatingAIVideo] = useState(false);
    const [isGettingPredictionStatus, setGettingPredictionStatus] = useState(false)
    const [isThumbsUpSending, setIsThumbsUpSending] = useState(false);
    const [isThumbsDownSending, setIsThumbsDownSending] = useState(false);
    const [previousFeedback, setPreviousFeedback] = useState(null);
    const [isPreviousFeedbackLoading, setIsPreviousFeedbackLoading] = useState(false);


    const {
        selectedFacebookAccount, setSelectedFacebookAccount
    } = useContext(AppContext);

    const imagesStyle = {
        position: 'relative',
        cursor: 'pointer',
        marginBottom: '16px',
        borderRadius: '6px',
        width: '250px',
    }

    const thumbsStyle = {
        fontSize: 35,
        color: 'transparent',
        filter: 'invert(1)'
    }


    const getPredictionStatus = (predictionId) => {
        console.log(predictionId)
        const inputData = {prediction_id: predictionId}
        setGettingPredictionStatus(true)
        getPredictionStatusApi(params.toString(), inputData)
            .then(res => {
                const data = res.data;

                if (res.data === null) {
                    console.log('Error', res.error);
                    return;
                }

                console.log('data', data)
                const aiImages = data.ai_images;

                console.log('aiImages', aiImages)
                // setAIImagesData(aiImages)
                // setIsAIImagesData(aiImages.length > 0)


                // setPredictionPercentage(data.percentage_completed)
                // console.log('predictionPercentage', predictionPercentage)


            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setGettingPredictionStatus(false)
            })
    }

    const shareOnInstagram = async (url, caption) => {
        const inputData = {
            "account_id": selectedFacebookAccount,
            "image_url": url,
            "caption": caption
        }
        setIsShareOnInstagram(true);
        const response = await facebookPublishImage(params.toString(), inputData);
        setIsShareOnInstagram(false);
        if (response.data === null) {
            console.log('Error', response.error);
            return;
        }
        console.log('response', response.data);

        setShowError('');

    }


    useEffect(() => {
        // getProductPrediction(productId)
        // getPredictionFeedback();

    }, []);


    return (
        <>
            <CustomModal
                title={`AI Video - ${productName}`}
                // open={isImageModal}
                // setOpen={setIsImageModal}
                leftButtonText={'Close'}
                // leftOnClick={() => setIsImageModal(false)}
            >
                <MediaCard
                    title={'AI Video'}
                    size={"medium"}
                    primaryAction={{
                        content: 'View/Download',
                        // onAction: () => window.open(selectedAiImage, '_blank')
                    }}
                >
                    <img
                        alt=""
                        width="100%"
                        height="100%"
                        style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                        }}
                        onMouseMove={handleMouseMoveZoom}
                        onMouseOut={handleMouseOutZoom}
                        // src={selectedAiImage}
                    />
                </MediaCard>
            </CustomModal>

            <IndexTable.Row
                id={id}
                predictionId={predictionId}
                predictionStatus={predictionStatus}
                state={state}
                startImageUrl={startImageUrl}
                endImageUrl={endImageUrl}
                userPrompt={userPrompt}
                video={video}
                predictionResult={predictionResult}
                productName={productName}
                CreatedAt={CreatedAt}
                key={id}
                position={index}
            >
                <IndexTable.Cell>
                    <InlineStack align={'start'} gap={'200'}>
                        <div
                            style={imagesStyle}
                            onMouseMove={handleMouseMoveZoom}
                            onMouseOut={handleMouseOutZoom}
                        >
                            <Card>
                                <img
                                    src={startImageUrl}
                                    alt={productName}
                                    style={{width: '100%'}}
                                />
                            </Card>

                        </div>
                    </InlineStack>
                </IndexTable.Cell>

                <IndexTable.Cell>
                    {endImageUrl &&
                        <InlineStack align={'start'} gap={'200'}>
                            <div
                                style={imagesStyle}
                                onMouseMove={handleMouseMoveZoom}
                                onMouseOut={handleMouseOutZoom}
                            >
                                <Card>
                                    <img
                                        src={endImageUrl ? endImageUrl : 'images/no_image.jpg'}
                                        alt={productName}
                                        style={{width: '100%'}}
                                    />
                                </Card>

                            </div>
                        </InlineStack>
                    }
                </IndexTable.Cell>

                <IndexTable.Cell>
                    <div style={{width: '250px', textWrap: 'wrap'}}>
                        <Text variant="bodyMd" as="span">
                            {userPrompt}
                        </Text>-
                    </div>
                </IndexTable.Cell>

                <IndexTable.Cell>
                    <div
                        style={imagesStyle}
                    >
                        {predictionStatus === 'completed' ?
                            <Tabs defaultActiveKey={'0'} appearance="subtle">
                                {predictionResult.filter(item => item.prediction_status !== 'terminated')
                                    .map((item, index) => (
                                        <Tabs.Tab title={`Video#${index + 1}`} eventKey={`${index}`}>
                                            <Card>

                                                <BlockStack gap={'200'}>
                                                    <InlineStack align={'center'} gap={'200'}>
                                                        {
                                                            item.output !== null ? (
                                                                <video width="95%" controls title={CreatedAt}>
                                                                    <source src={item.output.url} type="video/mp4"/>
                                                                </video>
                                                            ) : <Placeholder.Graph active/>
                                                        }
                                                    </InlineStack>
                                                    <ThumbFeedback
                                                        id={id}
                                                        prediction_result_id={item.id}
                                                        state={predictionStatus}
                                                        CreatedAt={CreatedAt}
                                                    />
                                                </BlockStack>

                                            </Card>
                                        </Tabs.Tab>

                                    ))}
                            </Tabs>

                            :
                            <Placeholder.Graph active width={320} height={240}/>
                        }

                    </div>

                </IndexTable.Cell>

                {/*<IndexTable.Cell>
                    <InlineStack align={'center'} gap={'200'} inlineAlign={'center'}>
                        <ThumbFeedback
                            id={id}
                            state={state}
                            CreatedAt={CreatedAt}
                        />
                    </InlineStack>


                </IndexTable.Cell>*/}


            </IndexTable.Row>
        </>
    )
}
