import Panel from "rsuite/Panel";
import {Button, Form, Table, Toggle, Input, IconButton, Progress} from "rsuite";
import {Link, useNavigate} from "react-router-dom";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {getAuthSession} from "../../../servies/common.services";
import ToasterMessage from "../../../components/common/toaster_message";
import ReloadIcon from '@rsuite/icons/Reload';
import StopOutlineIcon from '@rsuite/icons/StopOutline';
import RunningRoundIcon from '@rsuite/icons/RunningRound';

import {
    predictionJobsApi,
    resetPredictionJobs,
    updateSettings,
    updateSingleJobStatusApi
} from "../../../servies/settings.services";


import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import {round} from "lodash";

const {Column, HeaderCell, Cell} = Table;

export const PredictionJob = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [paramsData, setParamsData] = useState('');
    const [authData, setAuthData] = useState({});
    const [selectedRow, setSelectedRow] = useState({});
    const [predictionsData, setPredictionsData] = useState([]);
    const [openSettingFormModal, setOpenSettingFormModal] = useState(false);
    const [isRestatingJob, setIsRestatingJob] = useState(false);
    const [isUpdatingRow, setIsUpdatingRow] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);


    const navigate = useNavigate();

    const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref}/>);


    const handelRestartJobs = () => {
        setIsRestatingJob(true);

        resetPredictionJobs(paramsData)
            .then((res) => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }
                ToasterMessage.success(res.message);
                setIsRestatingJob(false);

            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                setIsRestatingJob(false);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                console.log('finally');
                setIsRestatingJob(false);
            })

    }

    const handelSingleJobStatus = (jobId, status) => {
        const inputData = {
            prediction_job_id: jobId,
            prediction_status: status
        }

        setIsUpdatingRow(true);

        updateSingleJobStatusApi(paramsData, inputData)
            .then((res) => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }
                ToasterMessage.success(res.message);

                const updatedPredictionsData = predictionsData.map((prediction) => {
                    if (prediction.prediction_job_id === jobId) {
                        return {...prediction, prediction_status: status};
                    }
                    return prediction;
                });

                setPredictionsData(updatedPredictionsData);

            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                console.log('finally');
                setIsUpdatingRow(false);
            })
    }

    const ImageCell = ({rowData, dataKey, ...props}) => (
        <Cell {...props}>
            <img src={rowData[dataKey]} width="50"/>
        </Cell>
    );

    const rowKey = 'prediction_job_id';

    const ExpandCell = ({rowData, dataKey, expandedRowKeys, onChange, ...props}) => (
        <Cell {...props} style={{padding: 5}}>
            <IconButton
                appearance="subtle"
                onClick={() => {
                    onChange(rowData);
                }}
                icon={
                    expandedRowKeys.some(key => key === rowData[rowKey]) ? (
                        <CollaspedOutlineIcon/>
                    ) : (
                        <ExpandOutlineIcon/>
                    )
                }
            />
        </Cell>
    );

    const ExpandCellTable = ({prediction_result}) => {
        console.log(prediction_result);

        return (<Table
            bordered
            data={prediction_result}
            rowKey={'id'}
        >

            <Column>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey='id'/>
            </Column>

            <Column fullText>
                <HeaderCell>Task Id</HeaderCell>
                <Cell dataKey='task_id'/>
            </Column>

            <Column>
                <HeaderCell>Model Name</HeaderCell>
                <Cell dataKey='model_name'/>
            </Column>

            <Column>
                <HeaderCell>State</HeaderCell>
                <Cell dataKey='state'/>
            </Column>

            <Column width={120}>
                <HeaderCell>Prediction Status</HeaderCell>
                <Cell dataKey='prediction_status'/>
            </Column>

            <Column>
                <HeaderCell>Error</HeaderCell>
                <Cell dataKey='error'/>
            </Column>

            <Column>
                <HeaderCell>retry count</HeaderCell>
                <Cell dataKey='retry_count'/>
            </Column>

            <Column width={250}>
                <HeaderCell>Created At</HeaderCell>
                <Cell dataKey='created_at'/>
            </Column>

            <Column width={250}>
                <HeaderCell>Output</HeaderCell>
                <Cell>
                    {data => (
                        <a href={data.output?.url} target={'_blank'}>video</a>
                    )}

                </Cell>
            </Column>

        </Table>)
    };

    const handleExpanded = (rowData, dataKey) => {
        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach(key => {
            if (key === rowData[rowKey]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowData[rowKey]);
        }

        setExpandedRowKeys(nextExpandedRowKeys);
    };

    useEffect(() => {
        const authSession = getAuthSession();
        if (authSession === null) {
            navigate('/admin/login');
            return;
        }

        const token = authSession.token;
        setAuthData(authSession);

        const params = `token=${token}&is_admin=true`;
        setParamsData(params);
        setIsLoading(true);

        predictionJobsApi(params.toString())
            .then(res => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }

                setPredictionsData(data);

            })
            .catch(reason => {
                ToasterMessage.error(reason.toString() + 'Settings');
                console.log(reason);
            })
            .finally(() => setIsLoading(false));

    }, [openSettingFormModal])

    return (
        <Fragment>
            {/*{updateSettingsDataModalRender()}*/}
            <Panel>
                <Button
                    appearance='primary'
                    Style='margin-bottom: 10px'
                    onClick={handelRestartJobs}
                    loading={isRestatingJob}
                >

                    Restart jobs
                </Button>
                <Table
                    height={550}
                    rowExpandedHeight={200}


                    // width={'700'}
                    bordered
                    data={predictionsData}
                    loading={isLoading}
                    rowKey={rowKey}
                    expandedRowKeys={expandedRowKeys}
                    onRowClick={data => {
                        setSelectedRow(data);
                    }}
                    renderRowExpanded={ExpandCellTable}
                >

                    <Column width={70} align="center">
                        <HeaderCell>#</HeaderCell>
                        <ExpandCell dataKey="id" expandedRowKeys={expandedRowKeys} onChange={handleExpanded}/>
                    </Column>

                    <Column width={70} align="center">
                        <HeaderCell>Progress bar</HeaderCell>
                        <Cell>
                            {rowData => (
                                <div style={{width: 30, }}>

                                    <Progress.Circle percent={round(rowData?.aggregate_prediction_status[1],1)}
                                                     strokeColor={rowData?.aggregate_prediction_status[1] === 100 ? '#52c41a' : '#3385ff'}
                                                     status={rowData?.aggregate_prediction_status[1] === 100 ? 'success' : null}

                                    />
                                </div>
                            )}
                        </Cell>

                    </Column>

                    <Column resizable={true} width={120} fullText={true}>
                        <HeaderCell>Permanently Stop</HeaderCell>
                        <Cell>
                            {rowData => (
                                <Toggle
                                    loading={isUpdatingRow && selectedRow.prediction_job_id === rowData.prediction_job_id}
                                    color={'red'}
                                    checkedChildren={'Blocked Job'}
                                    unCheckedChildren={rowData.prediction_status}
                                    checked={rowData.prediction_status === 'terminated'}
                                    onChange={(value) => {
                                        handelSingleJobStatus(rowData.prediction_job_id, value ? 'terminated' : 'failed');
                                    }}
                                />
                            )}
                        </Cell>
                    </Column>
                    <Column fullText={true}>
                        <HeaderCell>Restart</HeaderCell>
                        <Cell
                            style={{padding: '6px'}}
                        >
                            {rowData => (
                                <IconButton
                                    loading={isUpdatingRow && selectedRow.prediction_job_id === rowData.prediction_job_id}
                                    color={'green'}
                                    appearance="primary"
                                    icon={<ReloadIcon/>}
                                    disabled={rowData.is_running}
                                    onClick={(value) => {
                                        handelSingleJobStatus(rowData.prediction_job_id, value ? 'restart' : 'failed');
                                    }}
                                />

                            )}
                        </Cell>
                    </Column>
                    <Column resizable={true} width={200} fullText={true}>
                        <HeaderCell>Store Name</HeaderCell>
                        <Cell dataKey='store_name'/>
                    </Column>
                    <Column width={230} resizable>
                        <HeaderCell>Shop URL</HeaderCell>

                        <Cell style={{padding: '6px'}}>
                            {rowData => (
                                <Link to={{pathname: '/', search: `?${paramsData}&shop=${rowData.myshopify_domain}`,}}
                                      target={'_blank'}>
                                    {rowData.myshopify_domain}
                                </Link>
                            )}
                        </Cell>
                    </Column>

                    <Column>
                        <HeaderCell>Start Image</HeaderCell>
                        <ImageCell dataKey="start_image_url"/>
                    </Column>

                    <Column>
                        <HeaderCell>End Image</HeaderCell>
                        <ImageCell dataKey="end_image_url"/>
                    </Column>

                    <Column resizable={true} width={200} fullText={true}>
                        <HeaderCell>Prediction Id</HeaderCell>
                        <Cell dataKey="prediction_id"/>
                    </Column>

                    <Column resizable={true} width={200} fullText={true}>
                        <HeaderCell>State</HeaderCell>
                        <Cell dataKey='state'/>
                    </Column>

                    <Column resizable={true} fullText={true}>
                        <HeaderCell>Job Id</HeaderCell>
                        <Cell dataKey="prediction_job_id"/>
                    </Column>


                    <Column resizable={true} width={200} fullText={true}>
                        <HeaderCell>Prediction Status</HeaderCell>
                        <Cell dataKey='prediction_status'/>
                    </Column>

                    <Column resizable={true} width={80} fullText={true}>
                        <HeaderCell>is Running</HeaderCell>
                        <Cell>
                            {rowData => (
                                <IconButton
                                    style={{padding: '6px'}}
                                    icon={rowData.is_running || rowData.prediction_status === 'processing' ?
                                        <RunningRoundIcon/>
                                        :
                                        <StopOutlineIcon/>
                                    }
                                    appearance={'primary'}
                                    disabled={true}
                                    color={rowData.prediction_status === 'retrying' ? 'orange' : rowData.prediction_status === 'processing' ? 'green' : 'red'}
                                />

                            )}
                        </Cell>
                    </Column>
                    <Column resizable={true} width={80} fullText={true}>
                        <HeaderCell>Retry Count</HeaderCell>
                        <Cell dataKey='retry_count'/>
                    </Column>
                    <Column resizable={true} width={80} fullText={true}>
                        <HeaderCell>Error Messange</HeaderCell>
                        <Cell dataKey='error'/>
                    </Column>

                    <Column resizable={true} width={220} fullText={true}>
                        <HeaderCell>Created At</HeaderCell>
                        <Cell dataKey='created_at'/>
                    </Column>


                    {/*<Column fixed="right" resizable={true}>
                        <HeaderCell>Actions</HeaderCell>

                        <Cell style={{padding: '6px'}}>
                            {rowData => (
                                <div>
                                    <Button
                                        appearance="link"
                                        onClick={() => {
                                            setSelectedRow(rowData);
                                            setFormValue({name:rowData.name,value:rowData.value});
                                            setOpenSettingFormModal(true);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            )}
                        </Cell>
                    </Column>*/}

                </Table>

            </Panel>
        </Fragment>
    )
}